/* Intro*/
.intro-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
  background-color: rgba(47, 199, 233, 0.873);
  background-image: radial-gradient(circle at 1% 2%, hsla(277, 61%, 61%, 0.751) 7%, transparent 84%),
                    radial-gradient(circle at 95% 96%, hsla(261, 51%, 54%, 0.344) 7%, transparent 84%),
                    radial-gradient(circle at 2% 98%, hsla(296, 73%, 71%, 0.348) 7%, transparent 84%);
  padding: 20px;
}

.intro-text {
  font-size: 3vw; /* Ajuste dinámico según el ancho de la pantalla */
  font-weight: bold;
  color: #f9f9f9;
  letter-spacing: 0.05em;
  line-height: 1.3;
  font-family: 'Poppins', sans-serif;
  max-width: 46%;
  margin-left: 5%; /* Ajuste dinámico de márgenes */
}

.intro-image-pose {
  width: 24vw; /* Ajuste dinámico según el ancho de la pantalla */
  border-radius: 8px;
  margin-right: 2vw;
}

.intro-image-top {
  width: 20vw; /* Ajuste dinámico según el ancho de la pantalla */
  border-radius: 8px;
  margin-left: 2vw;
}

.intro-image-container-pose {
  display: flex;
  justify-content: center;
  width: 100%;
}

.intro-image-top-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .intro-text {
    font-size: 4vw; /* Aumenta el tamaño del texto en pantallas más pequeñas */
  }

  .intro-image-pose, .intro-image-top {
    width: 30vw; /* Ajusta el tamaño de las imágenes en pantallas pequeñas */
  }
}


/* Estilos para la parte media */
.content-section-media {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
  background-color: #f9f9f9;
  padding: 20px;
}

.text-left {
  flex: 1;
  padding: 10px;
  max-width: 45%; /* Ajuste de ancho para permitir expansión horizontal */
  box-sizing: border-box;
  border: 2px solid #b388e4;
  border-radius: 8px;
  transition: max-width 0.3s ease-in-out; /* Animación para cambios suaves */
  margin-left: 60px;
}

.image-container {
  max-width: 45%; /* Ancho dinámico */
  display: block; /* Asegura que la imagen sea tratada como un bloque */
  margin: 20px auto; /* Centra la imagen horizontalmente y añade margen superior */
  transition: margin-left 0.3s ease-in-out; /* Transición suave */
}

.pose-image {
  width: 60%; /* Ajusta el tamaño de la imagen */
  border-radius: 8px; /* Mantiene el borde redondeado */
  display: block; /* Asegura que la imagen sea tratada como un bloque */
  margin: 20px auto; /* Centra la imagen horizontalmente y añade margen superior */
}

.dialogapp-section {
  text-align: justify;
}

/* Media query para pantallas más pequeñas o cuando el texto colisione con la imagen */
@media (max-width: 1024px) {
  .content-section-media {
    flex-direction: column; /* Cambia a columna */
    align-items: center; /* Centra el contenido */
  }

  .text-left {
    max-width: 100%; /* El texto ocupará todo el ancho disponible */
    margin-bottom: 20px; /* Añade margen inferior para separar del texto */
    margin-left: 0; /* Elimina el margen izquierdo */
  }

  .image-container {
    max-width: 80%; /* Ajusta el tamaño de la imagen */
    
  }
}

@media (max-width: 768px) {
  .text-left {
    max-width: 100%;
    font-size: 1rem; /* Ajuste de tamaño de texto en pantallas pequeñas */
  }

  .pose-image {
    max-width: 80%; /* Reduce tamaño de la imagen en pantallas pequeñas */
  }
}


/* Estilos para el video */
.video-wrapper {
  display: flex;
  flex-direction: row; /* Mantiene el texto y video en fila en pantallas grandes */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 40px 0; /* Espaciado alrededor */
  background-color: rgba(47, 199, 233, 0.873);
  background-image: radial-gradient(circle at 1% 2%, hsla(277, 61%, 61%, 0.751) 7%, transparent 84%), 
                    radial-gradient(circle at 95% 96%, hsla(261, 51%, 54%, 0.344) 7%, transparent 84%), 
                    radial-gradient(circle at 2% 98%, hsla(296, 73%, 71%, 0.348) 7%, transparent 84%);
  flex-wrap: wrap; /* Permite que los elementos se ajusten en pantalla pequeña */
}

.video-title {
  font-size: 2rem;
  font-weight: bold;
  color: #f9f9f9;
  letter-spacing: 0.05em;
  line-height: 1.3;
  font-family: 'Poppins', sans-serif;
  max-width: 30%;
  margin: 0 20px;
  margin-left: 70px; /* Ajusta el margen izquierdo para mover el texto a la derecha */
  flex: 1;
}

.video-container {
  width: 100%;
  max-width: 50vw; /* El video ocupará el 50% del ancho de la pantalla */
  aspect-ratio: 16 / 9; /* Mantiene la relación de aspecto 16:9 */
  margin: 0 auto; /* Centra el contenedor del video */
  border-radius: 8px;
}

.video-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Media queries para pantallas pequeñas */
@media (max-width: 1024px) {
  .video-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .video-title {
    max-width: 90%;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 0; /* Elimina el margen izquierdo */
  }

  .video-container {
    max-width: 70vw; /* El video ocupará el 70% del ancho de la pantalla */
  }
}

@media (max-width: 768px) {
  .video-title {
    font-size: 1.5rem; /* Reduce el tamaño del texto en pantallas pequeñas */
  }

  .video-container {
    max-width: 90vw; /* El video ocupará el 90% del ancho de la pantalla */
  }
}

@media (max-width: 480px) {
  .video-container {
    max-width: 100vw; /* El video ocupará el 100% del ancho de la pantalla en móviles */
  }
}



/* Estilos para la parte inferior */
.content-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
  padding: 20px;
  flex-wrap: wrap; /* Permite que los elementos se ajusten en pantallas más pequeñas */
}

.mission-value-section {

  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-right: 20px;
  text-align: justify;
}

.mission-value-section h2 {
  text-align: left;
  color: #000000;
}

.image-container-pose {
  max-width: 45%; /* Ancho dinámico */
  transition: margin-left 0.3s ease-in-out; /* Transición suave */
  display: block; /* Asegura que la imagen sea tratada como un bloque */
  margin: 20px auto; /* Centra la imagen horizontalmente y añade margen superior */
  
}

/* Media queries para pantallas pequeñas */
@media (max-width: 1024px) {
  .content-section {
    flex-direction: column; /* Cambia la dirección del flex a columna en pantallas más pequeñas */
    align-items: center; /* Centra los elementos en la columna */
  }

  .mission-value-section {
    max-width: 100%; /* Permite que el texto ocupe todo el ancho disponible */
    margin-right: 0; /* Elimina el margen derecho */
  }

  .image-container-pose {
    max-width: 80%; /* Ajusta el tamaño de la imagen */
    margin: 20px auto 0 auto; /* Centra la imagen horizontalmente y añade margen superior */
    display: block; /* Asegura que el contenedor de la imagen se comporte como un bloque */
  }

  .pose-image {
    width: 60%; /* Ajusta el tamaño de la imagen */
    border-radius: 8px; /* Mantiene el borde redondeado */
    display: block; /* Asegura que la imagen sea tratada como un bloque */
    margin: 20px auto; /* Centra la imagen horizontalmente y añade margen superior */
  }

}


