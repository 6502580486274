.about-us-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.about-us-container h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: #333;
}

.mission-vision-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    gap: 20px;
    flex-wrap: wrap; /* Para que los elementos se apilen en pantallas pequeñas */
    text-align: justify;
}

.mission, .vision {
    width: 45%;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 2px solid #0077b5;
    box-sizing: border-box; /* Asegura que padding no afecte el ancho */
}

.mission h2, .vision h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #007bff;
}

.mission p, .vision p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}

/* Estilos responsive para la misión y visión */
@media (max-width: 768px) {
    .mission, .vision {
        width: 100%; /* Ocupa el 100% del ancho en pantallas pequeñas */
        margin-bottom: 20px; /* Añade margen para separación entre columnas */
    }
}

/* Estilos para agrupar en filas de 3 para pantallas grandes */
.team-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Tres columnas en pantallas grandes */
    gap: 20px;
    justify-items: center;
}

/* Ajustes responsive para pantallas medianas y pequeñas */
@media (max-width: 992px) {
    .team-container {
        grid-template-columns: repeat(2, 1fr); /* Dos columnas en pantallas medianas */
    }
}

@media (max-width: 768px) {
    .team-container {
        grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
    }
}

.team-member {
    width: 100%;
    max-width: 200px; /* Asegura que el tamaño máximo de las tarjetas no exceda */
    height: auto;
    background-color: #ffffff;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    transition: transform 0.3s ease;
    border: 2px solid #0077b5;
}

.team-member:hover {
    transform: translateY(-10px);
}

.team-member img {
    width: 100%;
    max-width: 200px;
    height: 200px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
}

.team-member h3 {
    font-size: 1.2rem;
    margin-top: 10px;
    color: #333;
}

.team-member p {
    font-size: 1rem;
    color: #777;
}

.linkedin-icon {
    color: #0077b5;
    margin-top: 10px;
    display: inline-block;
    transition: color 0.3s ease;
}

.linkedin-icon:hover {
    color: #005582;
}

.team-title {
    text-align: center;
    font-size: 2rem;
    margin: 40px 0 20px;
    color: #333;
}

/* Ajuste del margen superior entre el navbar y el contenido */
.about-us-container {
    margin-top: 60px; /* Ajusta según el tamaño del navbar */
}

.logo-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.about-logo {
    width: 150px;
    height: auto;
    margin-right: 20px;
}

.auto-text {
    font-size: 16px;
    text-align: left;
    color:#333;
    max-width: 400px;
  }