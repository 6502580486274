  body {
    padding-top: 70px; /* Ajusta este valor a la altura de tu navbar */
  }

  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(47, 199, 233, 0.873);
    background-image: radial-gradient(circle at 1% 2%, hsla(277, 61%, 61%, 0.751) 7%, transparent 84%), 
                      radial-gradient(circle at 95% 96%, hsla(261, 51%, 54%, 0.344) 7%, transparent 84%), 
                      radial-gradient(circle at 2% 98%, hsla(296, 73%, 71%, 0.348) 7%, transparent 84%);
    display: flex;
    justify-content: space-between; /* Alinea todo el contenido a la izquierda */
    align-items: center;
    padding: 0px 20px;
    box-sizing: border-box;
    z-index: 1000;
    height: 70px; /* Asegúrate de que la altura esté bien definida */
  }

  .logo {
    max-height: 60px;
    width: auto;
  }

  .nav-links {
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
    justify-content: center;
    flex: 1; /* Permite que .nav-links ocupe el espacio disponible */
  }

  .nav-links a {
    color: rgba(255, 255, 255, 0.947);
    text-decoration: none;
    font-size: 16px;
    position: relative; /* Necesario para usar ::after */
    padding-bottom: 5px; /* Espacio para la línea */
    transition: color 0.3s ease;
  }

  .nav-links a::after {
    content: '';
    position: absolute;
    bottom: 0; /* Línea justo debajo del texto */
    left: 0;
    width: 0%; /* Inicia invisible */
    height: 2px; /* Grosor de la línea */
    background-color: white; /* Color de la línea */
    transition: width 0.3s ease; /* Animación suave */
  }

  .nav-links a:hover::after {
    width: 100%; /* Se expande la línea al pasar el mouse */
  }

  .nav-links a.active::after {
    width: 100%; /* Aplica la barra blanca al enlace activo */
  }
  
  .nav-links a:hover {
    color: #ccc;
  }

  .menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-right: 10px; /* Espacio entre el icono de menú y el logo */
  }

  .menu-icon .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: all 0.3s ease;
  }

  .menu-icon.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .menu-icon.open .bar:nth-child(2) {
    opacity: 0; /* Oculta la barra del medio */
  }

  .menu-icon.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  .navbar-left {
    display: flex;
    align-items: center;
  }

  .navbar-right {
    display: flex;
    gap: 15px;
    flex: 0 1 auto; /* Permite que los íconos sociales no se expandan */
  }

  .social-icon {
    color: black;
    width: 24px;
    height: 24px;
  }

  .close-menu-btn {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.947);
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Alinea el botón a la derecha */
    position: absolute;
    top: 10px;
    right: 10px; /* Posiciona el botón en la esquina superior derecha */
    transition: color 0.3s ease;
  }
  
  .close-menu-btn:hover {
    color: #ccc;
  }
  
  .close-menu-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    cursor: pointer;
    display: none; /* Oculto por defecto */
  }

  @media (max-width: 768px) {
    .menu-icon {
      display: flex; /* Muestra el ícono de menú en dispositivos móviles */
    }

    .nav-links {
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: fixed;
      top: 0;
      left: -100%; /* Fuera de la vista al principio */
      height: 100%;
      width: 200px;
      background-color: rgba(85, 82, 219, 0.873);
      background-image: radial-gradient(circle at 1% 2%, hsla(277, 61%, 61%, 0.751) 7%, transparent 84%), 
                        radial-gradient(circle at 95% 96%, hsla(261, 51%, 54%, 0.344) 7%, transparent 84%), 
                        radial-gradient(circle at 2% 98%, hsla(296, 100%, 51%, 0.25) 7%, transparent 84%);
      justify-content: flex-start;
      padding: 60px 20px;
      text-align: left;
      transition: left 0.3s ease; /* Animación para deslizar el menú */
      z-index: 999;
    }

    .nav-links.open {
      left: 0; /* El menú aparece desde la izquierda */
    }

    

    @media (max-width: 768px) {
      .nav-links.open .close-menu-icon {
        display: block;
      }
    }

  }
