/* Asegúrate de que el body y html llenen todo el viewport */
html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

/* El contenedor principal debe ocupar el espacio completo */
#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Aseguramos que el contenido ocupe el espacio restante y el footer quede abajo */
main {
    flex: 1;
    padding-bottom: 100px; /* Asegura espacio para que el footer no cubra contenido */
}

/* Footer siempre visible y al final */
.footer {
    background-color: rgba(47, 199, 233, 0.873); /* Mantén el color de fondo original */
    background-image: radial-gradient(circle at 1% 2%, hsla(277, 61%, 61%, 0.751) 7%, transparent 84%), 
                      radial-gradient(circle at 95% 96%, hsla(261, 51%, 54%, 0.344) 7%, transparent 84%), 
                      radial-gradient(circle at 2% 98%, hsla(296, 73%, 71%, 0.348) 7%, transparent 84%);
    padding: 10px 20px; /* Reducción del padding para disminuir la altura del footer */
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000;
}

/* Ajuste de las secciones del footer */
.footer-section {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Reducción del gap entre elementos */
    color: white;
}

.footer-section h3 {
    margin-bottom: 0; /* Reducción del margen para disminuir la altura */
    font-size: 18px; /* Reducción del tamaño del título */
}

.footer-section p, .footer-section a {
    color: rgba(255, 255, 255, 0.947);
    text-decoration: none;
    font-size: 13px; /* Reducción del tamaño del texto */
    margin: 0; /* Elimina cualquier margen predeterminado */
    line-height: 1.5;
}

.footer-section a:hover {
    color: #ccc;
}

/* Iconos de redes sociales */
.social-icons {
    display: flex;
    gap: 8px; /* Reducción del espacio entre iconos */
}

/* Contenedor para logos a la derecha */
.footer-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Alineación horizontal y tamaño de los logos */
.logo-container {
    display: flex;
    gap: 15px; /* Reducción del espacio entre los logos */
    justify-content: center;
    align-items: center;
}

.footer-logo {
    width: 140px; /* Reducción del tamaño de los logos */
    height: auto;
}

/* Adaptaciones para pantallas pequeñas */
@media (max-width: 768px) {
    .footer {
        position: relative; /* Cambia el footer a relativo para que no sea fijo */
        flex-direction: column;
        align-items: center;
        padding-bottom: 0; /* Ajuste de padding para evitar espacios extra en móviles */
    }

    .footer-section {
        margin-bottom: 15px;
        text-align: center;
    }

    .footer-logo {
        width: 60px; /* Ajuste del tamaño de logos en pantallas pequeñas */
    }

    .social-icons {
        justify-content: center; /* Centra los iconos de redes sociales */
        width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */
    }
}
