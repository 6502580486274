.contact-us-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.contact-title {
  text-align: center;
  margin-bottom: 30px;
}

.content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.social-post {
  flex: 1 1 600px;
  max-width: 650px;  
  overflow: hidden;
}

.social-post iframe {
  width: 100%;
  height: 900px;
  border: none;
  max-width: 100%;
}

/* Estilos específicos para el post de Instagram */
.social-post.instagram iframe {
  height: 850px;
}

/* Estilos específicos para el post de LinkedIn */
.social-post.linkedin iframe {
  height: 950px;
}

@media (max-width: 1300px) {
  .social-post {
    flex-basis: 100%;
    max-width: 600px;
  }
  
  .social-post iframe {
    height: 850px;
  }
  
  .social-post.instagram iframe {
    height: 800px;
  }
  
  .social-post.linkedin iframe {
    height: 900px;
  }
}

@media (max-width: 768px) {
  .content-container {
    gap: 30px;
  }

  .social-post iframe {
    height: 800px;
  }
  
  .social-post.instagram iframe {
    height: 750px;
  }
  
  .social-post.linkedin iframe {
    height: 850px;
  }
}

@media (max-width: 480px) {
  .social-post iframe {
    height: 750px;
  }
  
  .social-post.instagram iframe {
    height: 700px;
  }
  
  .social-post.linkedin iframe {
    height: 800px;
  }
}

